import React, { useEffect, useState } from "react"
import moment from "moment";
import {
  ComposedChart, Bar, Cell, XAxis, YAxis, CartesianAxis, CartesianGrid, Tooltip, Label, Line
} from 'recharts';

import { capitalize, exportCSVFile } from "../../components/util";
import Layout from "../../components/layout"
import Intro from "../../components/intro"

const fetchCovidData = async shelter => {
  const URL = `https://corona.lmao.ninja/v2/historical`
  const response = await fetch(URL)
  if (response.status === 200) {
    return await response.json()
  }
}

const Covid19Questions = () => {
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState();
  const formatXAxis = date => dateRange.includes(date) ? moment(date).format("MMM. DD") : '';
  const [csvData, setCsvData] = useState();
  const [headers] = useState({
    date: 'date',
    cases: 'cases',
    deaths: 'deaths',
    recovered: 'recovered'
  });
  const [shelterData, setShelterData] = useState();
  const [province, setProvince] = useState();

  useEffect(() => {
    fetchCovidData().then(res => setData(res))
    const today = new Date();
    const yesterday = today.setDate(today.getDate() - 1);
    setDateRange(['3/1/2020', moment(yesterday).format("M/D/YY")]);
  }, [])

  useEffect(() => {
    if (province) {

    const selectedProvince = data.filter(item => item.province === province)
    const cases = selectedProvince[0].timeline.cases;
    const deaths = selectedProvince[0].timeline.deaths;
    const recovered = selectedProvince[0].timeline.recovered;
    const casesArray = [];
    const deathsArray = [];
    const recoveredArray = [];

    for (const key in cases) {
      casesArray.push({
        date: key,
        cases:  cases[key],
      })
    }

    for (const key in deaths) {
      deathsArray.push({
        date: key,
        deaths: deaths[key]
      })
    }

    for (const key in recovered) {
      recoveredArray.push({
        date: key,
        recovered: recovered[key]
      })
    }

    const result = casesArray.map(obj => {
      const deaths = deathsArray.find(item => item.date === obj.date);
      const recovered = recoveredArray.find(item => item.date === obj.date);

      return {...obj, ...deaths, ...recovered}
    }).filter(item => moment(item.date).isAfter('3/1/2020'));

    exportCSVFile(headers, result, `${province}-covid-cases-deaths`)
  }
  
  }, [province])

  const meta = {
    title: "Covid 19 Datasets for each Province in Canada",
    subject: "Math",
    description: `A timeline of the spread of Covid-19 for each Province in Canada.`,
    questions: [
      'Which province holds the greatest single day increase of cases?',
      'Based on these numbers, if the cases continue to grow at the same rate, estimate how many cases there will be in Ontario on May 1.'
    ],
    source: "https://github.com/NovelCOVID/API",
  }

  return (
    <Layout>
      <Intro data={meta} />
      {
        data && data
        .filter(item => item.country == 'Canada' && item.province !== 'diamond princess' && item.province !== 'recovered' && item.province !== 'grand princess')
        .map((item,i) => {
          const cases = item.timeline.cases;
          const deaths = item.timeline.deaths;
          const recovered = item.timeline.recovered;
          const tempResult = Object.keys(cases).map((key, i) => {
            return {date: key, cases: cases[key] - deaths[key] - recovered[key], deaths: deaths[key], recovered: recovered[key] };
          }).filter(item => moment(item.date).isAfter('3/1/2020'));

          const result = tempResult.map((item, i) => {
            const prevIndex = i - 1;
            let dailyPercentageChange;
            if (prevIndex >= 0) {
              dailyPercentageChange  = Math.round((tempResult[i].cases/tempResult[prevIndex].cases - 1) * 100 * 10) / 10;
            } else {
              dailyPercentageChange = 0
            }
            return {date: item.date, cases: item.cases, deaths: item.deaths, recovered: item.recovered, dailyPercentageDifference: (i > 0 && tempResult[prevIndex].cases > 0) ? dailyPercentageChange  : 0 };
          })
            return (
              <div key={i}>
                <h3 style={{textTransform: 'capitalize'}}>{item.province} <button style={{marginLeft: 10}} className="button" 
                onClick={() => {
                  setProvince(item.province)
                }
                }>Download CSV of {capitalize(item.province)}'s Data</button></h3>
                <br />
                <br />
                <h4>Totals</h4>
                <br />
                <ComposedChart
                  width={800}
                  height={300}
                  data={result}
                >
                  <XAxis
                    dataKey="date"
                    tickFormatter={formatXAxis}
                    dy={6}
                    tickLine={false}
                  >
                    <Label 
                      value={moment(dateRange[0]).format("MMM. DD")}
                      position="insideBottomLeft"
                      fill="#666"
                    />
                  </XAxis>
                  <YAxis allowDecimals={false} allowDataOverflow={true}  />
                  <CartesianAxis axisLine={false} />
                  <CartesianGrid vertical={false} strokeDasharray="3 3" />
                  <Tooltip />
                  <Bar dataKey="recovered" fill="#2ea2f8" stackId="b" />
                  <Bar dataKey="deaths" fill="#ec471b" stackId="b" />
                  <Bar dataKey="cases" fill="#82ca9d" stackId="b" />
                </ComposedChart>
                <br />
                <br />
                <h4>Daily Rate of Change</h4>
                <br />
                <ComposedChart
                  width={800}
                  height={150}
                  data={result}
                >
                  <XAxis
                    dataKey="date"
                    tickFormatter={formatXAxis}
                    dy={6}
                    dx={-10}
                    tickLine={false}
                  >
                    <Label 
                      value={moment(dateRange[0]).format("MMM. DD")}
                      position="insideBottomLeft"
                      fill="#666"
                    />
                  </XAxis>
                  <YAxis ticks={[0,100]} />
                  <CartesianAxis axisLine={false} />
                  <Tooltip />
                  <Line dataKey="dailyPercentageDifference" fill="orange" type="monotone" dot={false} />
                </ComposedChart>
                <br />
                <br />
                <br />
              </div>
            )
        })
      }
    </Layout>
  )
}

export default Covid19Questions
